<template>
  <div class="basicInfo">
    <div class="navbar">
      <div class="navbar_btn" @click="$router.go(-1)">
        <svg-icon iconClass="leftArrow"></svg-icon>
      </div>
      <div class="navbar_title">基本信息</div>
      <div class="rightBtn"></div>
    </div>
    <div class="itemBox">
      <div>头像</div>
      <van-uploader :after-read="afterRead">
        <ImgDecypt :src="userInfo.avatarUrl" round class="avatar" :key="userInfo.avatarUrl" />
      </van-uploader>
      <!-- <ImgDecypt :src="userInfo.avatarUrl" round class="avatar" :key="userInfo.avatarUrl" /> -->
    </div>
    <div class="itemBox">
      <div>ID</div>
      <div>{{userInfo.id}}</div>
    </div>
    <div class="itemBox" v-if="userInfo.mobile">
      <div>手机号</div>
      <div>{{userInfo.mobile}}</div>
    </div>
    <div class="itemBox" v-if="userInfo.email">
      <div>邮箱</div>
      <div>{{userInfo.email}}</div>
    </div>
    <div class="itemBox">
      <div>昵称</div>
      <div class="rightBox" @click="editName">
        <p>{{userInfo.nickName}}</p>
        <svg-icon iconClass="rightArrow"></svg-icon>
      </div>
    </div>
    <div class="itemBox">
      <div>性别</div>
      <div class="rightBox" @click="editGender">
        <p>{{userInfo.gender == 0 ? '男' : '女'}}</p>
        <svg-icon iconClass="rightArrow"></svg-icon>
      </div>
    </div>
    <!-- <div class="itemBox">
            <div>个性签名</div>
            <svg-icon iconClass="rightArrow"></svg-icon>
        </div> -->
    <div class="dialog" v-if="isEditName || isEditSex">
      <div class="dialogMask"></div>
      <div class="dialogBox">
        <div class="dialogTitle" v-if="isEditName">修改昵称</div>
        <van-field value="" placeholder="请输入昵称" size="13" v-model="userName" v-if="isEditName" />
        <div class="sexBox" v-if="isEditSex">
          <div class="sex" @click="changeGender(0)">
            <img src="@/assets/png/man.png">
            <div class="sexBtn" :class="{isActive: userSex == 0}">男</div>
          </div>
          <div class="sex" @click="changeGender(1)">
            <img src="@/assets/png/woman.png">
            <div class="sexBtn" :class="{isActive: userSex == 1}">女</div>
          </div>
        </div>
        <div class="btnBox">
          <div class="btn" @click="cancel">取消</div>
          <div class="line"></div>
          <div class="btn" @click="modifyUserInfo">确定</div>
        </div>
      </div>
    </div>
    <!-- <div class="dialog" v-if="isEditIntroduc">
            <div class="dialogMask"></div>
            <div class="dialogBox">
                <div class="dialogTitle">个人简介</div>
                <van-field value="" show-word-limit maxlength="100" placeholder="请输入个人简介" size="13" />
                <div class="sexBox" v-if="isEditSex">
                    <div class="sex">
                        <img src="@/assets/png/man.png">
                        <div class="sexBtn isActive">男</div>
                    </div>
                    <div class="sex">
                        <img src="@/assets/png/woman.png">
                        <div class="sexBtn">女</div>
                    </div>
                </div>
                <div class="btnBox">
                    <div class="btn">取消</div>
                    <div class="line"></div>
                    <div class="btn">确定</div>
                </div>
            </div>
        </div> -->
  </div>
</template>

<script>
import { getUserInfo, modifyUserInfo, editAvatar } from "@/api/user.js";
import { uploadImg } from "@/api/loufeng.js";
export default {
  data() {
    return {
      isEditName: false,
      isEditSex: false,
      isEditIntroduc: true,
      userName: "",
      userSex: 0,
      userInfo: {},
      fileList: [
        // {
        // url: 'https://www.youpai5.com/uploadfile/2020/12/1e0ed8b8b93f432f.jpg'
        // },
        // Uploader 根据文件后缀来判断是否为图片文件
        // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
      ],
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    // 获取用户信息
    async getUserInfo() {
      let ret = await this.$Api(getUserInfo, undefined);
      if (ret && ret.code == 200) {
        sessionStorage.setItem("userInfo", JSON.stringify(ret.data.info)); //存储自己的用户信息
        this.userInfo = ret.data.info;
      }
    },
    // 上传头像
    async afterRead(file) {
      let formData = new FormData();
      formData.append("file", file.file);
      let ret = await this.$Api(uploadImg, formData);
      if (ret && ret.code == 200) {
        this.editAvatar(ret.data.path);
      } else {
        this.$toast(ret.tip || "上传失败");
      }
    },
    // 取消修改
    cancel() {
      this.isEditName = false;
      this.isEditSex = false;
      this.userName = "";
      this.userSex = "";
    },
    // 修改昵称
    editName() {
      if (this.userInfo.vipType != 0) {
        this.userName = this.userInfo.nickName;
        this.isEditName = true;
      } else {
        this.$toast("开通会员才能修改");
      }
    },
    // 选择性别
    changeGender(index) {
      this.userSex = index;
    },
    // 修改性别
    editGender() {
      this.userSex = this.userInfo.gender;
      this.isEditSex = true;
    },
    async editAvatar(imgUrl) {
      let req = {
        avatar: true,
        imgData: imgUrl,
      };
      let ret = await this.$Api(editAvatar, req);
      if (ret && ret.code == 200) {
        this.getUserInfo();
      } else {
        this.$toast(ret.tip || "修改失败");
      }
    },
    // 修改用户信息请求
    async modifyUserInfo() {
      let req = {
        nickName: this.isEditName ? this.userName : undefined,
        gender: this.isEditSex ? this.userSex : undefined,
      };
      if (this.isEditName && !this.userName) {
        this.$toast("请输入用户昵称");
        return;
      }
      let ret = await this.$Api(modifyUserInfo, req);
      if (ret && ret.code == 200) {
        this.$toast("修改成功");
        this.getUserInfo();
        this.cancel();
      } else {
        this.$toast(ret.tip || "修改失败");
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.basicInfo {
  background: $whiteThree;

  .navbar {
    // position: fixed;
    // top: 0;
    background: $vermillion;
    width: 100%;
    color: $white1;
    height: 44px;
    font-size: 20px;
    padding: 0 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .navbar_title {
      font-weight: 600;
    }

    .rightBtn {
      width: 25px;
    }
  }

  .itemBox {
    padding: 0 10px;
    height: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: $purpleBrown;
    background: $white1;
    margin-bottom: 1px;

    .avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }

    .rightBox {
      display: flex;
      align-items: center;

      p {
        margin-right: 8px;
      }
    }
  }

  .dialog {
    .dialogMask {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
    }

    .dialogBox {
      height: 154px;
      width: 277px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $black;
      background: $white1;
      border-radius: 14px;
      padding: 12px 0 0;

      .dialogTitle {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 14px;
        // margin-top: 12px;
      }

      // /deep/ .van-cell__value {
      //     border-bottom: 1px solid $whiteFive;
      // }

      .sexBox {
        margin-top: 8px;
        display: flex;
        justify-content: space-around;
        text-align: center;

        // align-items: center;
        .sex {
          img {
            width: 60px;
            height: 60px;
          }

          .sexBtn {
            width: 60px;
            height: 25px;
            line-height: 25px;
            margin-top: 4px;
            font-size: 14px;
            border-radius: 18px;
            // background: $paleMagenta;
            // background: #000;
          }

          .isActive {
            background: $paleMagenta;
            color: $white1;
          }
        }
      }

      .btnBox {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        border-top: 1px solid $whiteNine;

        // margin-top: 6px;
        .btn {
          flex: 2;
          text-align: center;
          font-size: 18px;
        }

        .line {
          width: 1px;
          height: 26px;
          background: $silver;
        }
      }
    }
  }
}
</style>